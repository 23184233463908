import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Switcher from "../Elements/Switcher";

var bgimage = require("./../../images/background/bg-site.png");

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.currentstate = { logo: require("./../../images/logo-dark.png") };
  }

  updateFooterLogo = (updatedlogo) => {
    this.currentstate.logo = updatedlogo;
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <footer className="site-footer footer-large footer-dark footer-wide">
          <div
            className="container call-to-action-wrap bg-no-repeat bg-center"
            style={{ backgroundImage: `url(${bgimage})` }}
          />
          <div className="footer-top overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                {/* ABOUT COMPANY */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_about">
                    <div className="logo-footer clearfix p-b15">
                      <NavLink to={"./"}>
                        <img src={this.currentstate.logo} alt="" />
                      </NavLink>
                    </div>
                    <p className="max-w400">{t("footer_about_text")}</p>
                  </div>
                </div>
                {/* CONTACT US */}
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="widget widget_address_outer">
                    <h4 className="widget-title">{t("contact_us")}</h4>
                    <ul className="widget_address">
                      <li>{t("laysen_valley_address")}</li>
                      <li>{t("info_email")}</li>
                      <h6>
                        <a href="tel:+966509181007">(+966) 50-918-1007</a>
                      </h6>
                      <h6>
                        <a href="tel:+966507769189">(+966) 50-776-9189</a>
                      </h6>
                      <h6>
                        <a href="tel:+966553635559">(+966) 55-363-5559</a>
                      </h6>
                    </ul>
                  </div>
                </div>
                {/* USEFUL LINKS */}
                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                  <div className="widget widget_services inline-links">
                    <h4 className="widget-title">{t("useful_links")}</h4>
                    <ul>
                      <li>
                        <NavLink to={"/home"}>{t("home")}</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/about"}>{t("about")}</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/services"}>{t("services")}</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/contactus"}>{t("contact_us")}</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                <div className="mt-footer-bot-center">
                  <span className="copyrights-text">
                    {t("footer_copyright")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)} />
      </>
    );
  }
}

export default withTranslation()(Footer);
