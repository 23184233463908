import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

class AboutCompany extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <div className="section-full p-t80 bg-white">
          <div className="container">
            <div className="section-content">
              <div className="m-service-containt text-black">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <div className="service-about-left">
                      <div className="mt-media">
                        <img src={require("./../../images/s-1.png")} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-12">
                    <div className="service-about-right m-b30">
                      <h3 className="m-t0">{t("about_company_title")}</h3>
                      <p>{t("about_company_paragraph1")}</p>
                      <p>{t("about_company_paragraph2")}</p>
                      <div className="call-for-quote-outer">
                        <div className="call-quote">
                          <span>{t("call_for_quote")}</span>
                          <h4>
                            <a href="tel:+966509181007">(+966) 50-918-1007</a>
                          </h4>
                          <h4>
                            <a href="tel:+966507769189">(+966) 50-776-9189</a>
                          </h4>
                          <h4>
                            <a href="tel:+966553635559">(+966) 55-363-5559</a>
                          </h4>
                        </div>
                        <div className="call-estimate bg-dark">
                          <NavLink
                            to={"/contactus"}
                            className="site-button-secondry btn-effect"
                          >
                            {t("online_estimate_form")}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(AboutCompany);
