import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

var bnr1 = require("./../../images/background/bg-1.jpg");

const Callus = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="section-full mobile-page-padding p-tb80 overlay-wraper bg-cover bg-center"
        style={{ backgroundImage: "url(" + bnr1 + ")" }}
      >
        <div className="overlay-main bg-primary opacity-07" />
        <div className="container">
          <div className="section-content">
            <div className="call-us-section text-center">
              <h4 className="m-b15">{t("lets_work_together")}</h4>
              <h4>
                <a href="tel:+966509181007">(+966) 50-918-1007</a>
              </h4>
              <h4>
                <a href="tel:+966507769189">(+966) 50-776-9189</a>
              </h4>
              <h4>
                <a href="tel:+966553635559">(+966) 55-363-5559</a>
              </h4>
              <h4 className="call-us-address m-t0 m-b20">
                {t("laysen_valley_address")}
              </h4>
              <NavLink
                to="/contactus"
                className="site-button-secondry btn-effect bg-dark"
              >
                {t("contact_us")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Callus;
